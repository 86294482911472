<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        type="pagetop"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            dense
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fire-detection-alarm-system-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: 'fireDetectionAlarmSystemInfo',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'fireDetectionAlarmSystemInfo', icon: 'info', label: '기본정보', component: () => import(`${'./fireDetectionAlarmSystemInfo.vue'}`) },  // 화재탐지 및 경보설비 설치계획
        { name: 'fireDetectionAlarmSystemPid', icon: 'tab', label: '도면', component: () => import(`${'./fireDetectionAlarmSystemPid.vue'}`) }, // 화재탐지 및 경보설비 설치계획도
        { name: 'fireDetectionAlarmSystemDoc', icon: 'assignment', label: '문서', component: () => import(`${'./fireDetectionAlarmSystemDoc.vue'}`) } // 화재탐지 및 경보설비 설치계획 문서
      ]
    },

  }
};
</script>